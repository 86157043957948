import { ReactElement, useCallback } from 'react'
import { ChainId, Currency } from '@pancakeswap/sdk'
import styled, { useTheme } from 'styled-components'
import {
  Button,
  Text,
  Image,
  // ErrorIcon,
  OpenNewIcon,
  SuccessIcon,
  SuccessLightIcon,
  FailureIcon,
  FailureLightIcon,
  Flex,
  Box,
  Link,
  // Spinner,
  Modal,
  InjectedModalProps,
  ModalProps,
  useMatchBreakpoints,
} from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
// import { wrappedCurrency } from 'utils/wrappedCurrency'
// import { WrappedTokenInfo } from '@pancakeswap/tokens'
import { AutoColumn, ColumnCenter } from '../Layout/Column'
import { getBlockExploreLink, getBlockExploreName } from '../../utils'
// import AddToWalletButton, { AddToWalletTextOptions } from '../AddToWallet/AddToWalletButton'

const Wrapper = styled.div`
  width: 100%;
`
const Section = styled(AutoColumn)`
  padding: 24px;
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 24px 0;
`

function ConfirmationPendingContent({ pendingText }: { pendingText: string }) {
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()
  const { isDark } = useTheme()

  return (
    <Wrapper>
      <Text fontSize={isMobile ? '14px' : '16px '} color="subTitle" textAlign="center">
        {t('Confirm this transaction in your wallet')}
      </Text>
      <ConfirmedIcon>
        {/* <Spinner /> */}
        <Image width={100} height={110} src={isDark ? '/images/loading.gif' : '/images/loading-light.gif'} />
      </ConfirmedIcon>
      <AutoColumn gap="8px" justify="center">
        <Text fontSize={isMobile ? '18px' : '22px '}>{t('Waiting For Confirmation')}</Text>
        <AutoColumn gap="12px" justify="center">
          <Text fontSize={isMobile ? '14px' : '18px '} textAlign="center" color="subTitle">
            {pendingText}
          </Text>
        </AutoColumn>
      </AutoColumn>
    </Wrapper>
  )
}

export function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  currencyToAdd,
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  currencyToAdd?: Currency | undefined
}) {
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()
  const { isDark } = useTheme()

  // const token: Token | undefined = wrappedCurrency(currencyToAdd, chainId)
  // console.log("交易确认弹框===", currencyToAdd, chainId)

  return (
    <Wrapper>
      <Section>
        {/* <ConfirmedIcon>
          <ArrowUpIcon strokeWidth={0.5} width="90px" color="primary" />
        </ConfirmedIcon> */}
        <AutoColumn gap="12px" justify="center">
          <Flex mb="16px">{isDark ? <SuccessIcon width="70" /> : <SuccessLightIcon width="70" />}</Flex>
          <Text fontSize={isMobile ? '20px' : '23px'}>{t('Transaction Submitted')}</Text>
          {chainId && hash && (
            <Link color="from" external href={getBlockExploreLink(hash, 'transaction', chainId)}>
              {t('View on %site%', {
                site: getBlockExploreName(chainId),
              })}
              <OpenNewIcon ml="6px" width="20px" />
            </Link>
          )}
          {/* {currencyToAdd && (
            <AddToWalletButton
              variant="tertiary"
              mt="12px"
              width="fit-content"
              marginTextBetweenLogo="6px"
              textOptions={AddToWalletTextOptions.TEXT_WITH_ASSET}
              tokenAddress={token.address}
              tokenSymbol={currencyToAdd.symbol}
              tokenDecimals={token.decimals}
              tokenLogo={token instanceof WrappedTokenInfo ? token.logoURI : undefined}
            />
          )} */}
          <Button className="global-btn" width="100%" onClick={onDismiss} mt="20px" variant="text">
            {t('Close')}
          </Button>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  bottomContent,
  topContent,
}: {
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  return (
    <Wrapper>
      <Box>{topContent()}</Box>
      <Box>{bottomContent()}</Box>
    </Wrapper>
  )
}

export function TransactionErrorContent({
  message,
  onDismiss,
}: {
  message: ReactElement | string
  onDismiss?: () => void
}) {
  const { t } = useTranslation()
  const { isDark } = useTheme()

  return (
    <Wrapper>
      <AutoColumn justify="center">
        {/* <ErrorIcon color="failure" width="64px" /> */}
        <Flex mb="28px">{isDark ? <FailureIcon width="71" /> : <FailureLightIcon width="71" />}</Flex>
        <Text color="failure" style={{ textAlign: 'center', width: '85%', wordBreak: 'break-word' }}>
          {message}
        </Text>
      </AutoColumn>

      {onDismiss ? (
        <Flex justifyContent="center" pt="24px">
          <Button className="global-btn" width="100%" onClick={onDismiss}>
            {t('Dismiss')}
          </Button>
        </Flex>
      ) : null}
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  title: string
  customOnDismiss?: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string
  currencyToAdd?: Currency | undefined
}

const TransactionConfirmationModal: React.FC<
  React.PropsWithChildren<InjectedModalProps & ConfirmationModalProps & ModalProps>
> = ({ title, onDismiss, customOnDismiss, attemptingTxn, hash, pendingText, content, currencyToAdd, ...props }) => {
  const { chainId } = useActiveWeb3React()

  const handleDismiss = useCallback(() => {
    if (customOnDismiss) {
      customOnDismiss()
    }
    onDismiss?.()
  }, [customOnDismiss, onDismiss])

  if (!chainId) return null

  return (
    <Modal title={title} headerBackground="gradients.cardHeader" {...props} onDismiss={handleDismiss}>
      {attemptingTxn ? (
        <ConfirmationPendingContent pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={handleDismiss}
          currencyToAdd={currencyToAdd}
        />
      ) : (
        content()
      )}
    </Modal>
  )
}

export default TransactionConfirmationModal
