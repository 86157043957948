import { Currency, Pair } from '@pancakeswap/sdk'
import { Button, ChevronDownIcon, Text, useModal, Flex, Box, useMatchBreakpoints } from '@pancakeswap/uikit'
import styled, { css } from 'styled-components'
import { useTranslation } from '@pancakeswap/localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo'

import { Input as NumericalInput } from './NumericalInput'

// const InputRow = styled.div<{ selected: boolean }>`
//   display: flex;
//   flex-flow: row nowrap;
//   align-items: center;
//   justify-content: flex-end;
//   padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')};
// `
const CurrencySelectButton = styled(Button).attrs({ variant: 'text', scale: 'sm' })<{ zapStyle?: ZapStyle }>`
  height: 54px;
  background: ${({ theme }) => theme.colors.selectCurrency};
  border-radius: 10px;

  ${({ zapStyle, theme }) =>
    zapStyle &&
    css`
      // background: ${theme.colors.background};
      // border: 1px solid ${theme.colors.cardBorder};
      // border-radius: ${zapStyle === 'zap' ? '0px' : '8px'} 8px 0px 0px;
      // height: auto;
    `};
`
// const LabelRow = styled.div`
//   display: flex;
//   flex-flow: row nowrap;
//   align-items: center;
//   color: ${({ theme }) => theme.colors.text};
//   font-size: 0.75rem;
//   line-height: 1rem;
//   // padding: 0.75rem 1rem;
// `
// const InputPanel = styled.div`
//   display: flex;
//   flex-flow: column nowrap;
//   position: relative;
//   border-radius: 20px;
//   background-color: ${({ theme }) => theme.colors.backgroundAlt};
//   z-index: 1;
// `
// const Container = styled.div<{ zapStyle?: ZapStyle; error?: boolean }>`
//   display: flex;
//   justify-content: space-between;
//   padding: 20px;
//   border-radius: ${({ theme }) => theme.radii.default};
//   background-color: ${({ theme }) => theme.colors.input};
//   box-shadow: ${({ theme }) => theme.colors.borderBoxShadowInset};
//   ${({ zapStyle }) =>
//     !!zapStyle &&
//     css`
//       // border-radius: 0px 16px 16px 16px;
//     `};
// `

type ZapStyle = 'noZap' | 'zap'

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onInputBlur?: () => void
  onMax?: () => void
  showMaxButton: boolean
  showAddLiquidity?: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  commonBasesType?: string
  zapStyle?: ZapStyle
  beforeButton?: React.ReactNode
  disabled?: boolean
  error?: boolean
  showBUSD?: boolean
  isLimit?: boolean
}
export default function CurrencyInputPanel({
  value,
  onUserInput,
  onInputBlur,
  onMax,
  showMaxButton,
  showAddLiquidity = false,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  zapStyle,
  beforeButton,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  showCommonBases,
  commonBasesType,
  disabled,
  error,
  showBUSD,
  isLimit,
}: CurrencyInputPanelProps) {
  const { account } = useActiveWeb3React()
  const { isMobile } = useMatchBreakpoints()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const { t } = useTranslation()

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      isLimit={isLimit}
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
      commonBasesType={commonBasesType}
    />,
  )

  // console.log("currency==========", showMaxButton, !disabled, account && currency && !disabled && showMaxButton && label !== 'To')

  return (
    <>
      {!showAddLiquidity ? (
        <>
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="from" small>
              {label}
            </Text>
            <Flex>
              {account && (
                <Text
                  // onClick={!disabled ? onMax : undefined}
                  color="textSubtle"
                  small
                  style={{ display: 'inline', cursor: 'pointer', textAlign: 'right' }}
                >
                  {!hideBalance && !!currency
                    ? t('Balance: %balance%', { balance: selectedCurrencyBalance?.toFixed(4) ?? t('Loading') })
                    : ' -'}
                </Text>
              )}

              {account && currency && !disabled && showMaxButton && label !== 'To' && (
                <Button onClick={onMax} scale="xs" variant="text" style={{ fontSize: 14 }} className="gradient-txt">
                  {t('Max')}
                </Button>
              )}
            </Flex>
          </Flex>

          <Box position="relative" id={id} className="input-box">
            <Flex alignItems="center" justifyContent="space-between">
              {/* {beforeButton} */}
              <NumericalInput
                error={error}
                disabled={disabled}
                className="token-amount-input"
                style={{ textAlign: 'left' }}
                value={value}
                onBlur={onInputBlur}
                onUserInput={(val) => {
                  onUserInput(val)
                }}
              />
              <CurrencySelectButton
                zapStyle={zapStyle}
                className="open-currency-select-button"
                selected={!!currency}
                onClick={() => {
                  if (!disableCurrencySelect) {
                    onPresentCurrencyModal()
                  }
                }}
              >
                <Flex alignItems="center" justifyContent="space-between">
                  {pair ? (
                    <></>
                  ) : currency ? (
                    <CurrencyLogo
                      currency={currency}
                      size={isMobile ? '24px' : '36px'}
                      style={{ marginRight: '8px' }}
                    />
                  ) : null}
                  {pair ? (
                    <Text id="pair" bold>
                      {pair?.token0.symbol}:{pair?.token1.symbol}
                    </Text>
                  ) : (
                    <Text id="pair" bold>
                      {(currency && currency.symbol && currency.symbol.length > 20
                        ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                            currency.symbol.length - 5,
                            currency.symbol.length,
                          )}`
                        : currency?.symbol) || t('Select a currency')}
                    </Text>
                  )}
                  {!disableCurrencySelect && <ChevronDownIcon />}
                </Flex>
              </CurrencySelectButton>
            </Flex>
          </Box>
        </>
      ) : (
        <>
          <Flex alignItems="center" justifyContent="space-between">
            <Text />
            {account && (
              <Flex alignItems="center">
                <Text
                  onClick={!disabled ? onMax : undefined}
                  color="textSubtle"
                  fontSize="14px"
                  style={{ display: 'inline', cursor: 'pointer', textAlign: 'right' }}
                >
                  {!hideBalance && !!currency
                    ? t('Balance: %balance%', { balance: selectedCurrencyBalance?.toFixed(4) ?? t('Loading') })
                    : ' -'}
                </Text>
                {/* <InputRow selected={disableCurrencySelect}> */}
                {account && currency && !disabled && showMaxButton && label !== 'To' && (
                  <Button className="gradient-txt" onClick={onMax} scale="xs" variant="text" style={{ fontSize: 14 }}>
                    {t('Max')}
                  </Button>
                )}
                {/* </InputRow> */}
              </Flex>
            )}
          </Flex>

          <Box position="relative" id={id} className="input-box">
            <Flex alignItems="center" justifyContent="space-between">
              <NumericalInput
                style={{ textAlign: 'left' }}
                error={error}
                disabled={disabled}
                className="token-amount-input"
                value={value}
                onBlur={onInputBlur}
                onUserInput={(val) => {
                  onUserInput(val)
                }}
              />

              <CurrencySelectButton
                zapStyle={zapStyle}
                className="open-currency-select-button"
                selected={!!currency}
                onClick={() => {
                  if (!disableCurrencySelect) {
                    onPresentCurrencyModal()
                  }
                }}
              >
                <Flex alignItems="center" justifyContent="space-between">
                  {pair ? (
                    <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
                  ) : currency ? (
                    <CurrencyLogo
                      currency={currency}
                      size={isMobile ? '24px' : '36px'}
                      style={{ marginRight: '8px' }}
                    />
                  ) : null}
                  {pair ? (
                    <Text id="pair" bold>
                      {pair?.token0.symbol}:{pair?.token1.symbol}
                    </Text>
                  ) : (
                    <Text id="pair" bold>
                      {(currency && currency.symbol && currency.symbol.length > 20
                        ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                            currency.symbol.length - 5,
                            currency.symbol.length,
                          )}`
                        : currency?.symbol) || t('Select a currency')}
                    </Text>
                  )}
                  {!disableCurrencySelect && <ChevronDownIcon />}
                </Flex>
              </CurrencySelectButton>
            </Flex>
          </Box>
        </>
      )}
    </>
  )
}
